import api from './setup'

export default {

  async findAll () {
    const { data } = await api.get('/businesses')
    return data
  },

  async findById (id) {
    const { data } = await api.get(`/businesses/${id}`)
    return data
  },

  async findByUserId (id) {
    const { data } = await api.get(`/businesses/users/${id}`)
    return data
  },

  async save (businesData) {
    // If the businesData has an id then run update busines
    if (businesData.id) {
      const { data } = await api.put(`/businesses/${businesData.id}`, businesData)
      return data
    }

    // Run create busines
    const { data } = await api.post('/businesses', businesData)
    return data
  },

  async delete (id) {
    return api.delete(`/businesses/${id}`)
  }

}
