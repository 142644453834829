<template>
  <form @submit.prevent="handleSubmit">
    <h4 class="mb-4">Business Information</h4>

    <b-row>
      <b-col>
        <b-form-group>
          <label><small>Business Name</small></label>
          <b-form-input required v-model="infoCopy.name"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group>
          <label><small>Trade Name / Franchise</small></label>
          <b-form-input required v-model="infoCopy.tradeName"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="5">
        <b-form-group>
          <label><small>President's First Name</small></label>
          <b-form-input
            required
            v-model="infoCopy.presidentFirstName"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="5">
        <b-form-group>
          <label><small>President's Last Name</small></label>
          <b-form-input
            required
            v-model="infoCopy.presidentLastName"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="2">
        <b-form-group>
          <label><small>President's MI</small></label>
          <b-form-input required v-model="infoCopy.presidentMi"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="5">
        <b-form-group>
          <label><small>House / Building No.</small></label>
          <b-form-input required v-model="infoCopy.buildingNo"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="5">
        <b-form-group>
          <label><small>Building Name</small></label>
          <b-form-input required v-model="infoCopy.buildingName"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="2">
        <b-form-group>
          <label><small>Unit No.</small></label>
          <b-form-input required v-model="infoCopy.unitNo"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-form-group>
          <label><small>Street</small></label>
          <b-form-input required v-model="infoCopy.street"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <label><small>Barangay</small></label>
          <b-form-input required v-model="infoCopy.barangay"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4">
        <b-form-group>
          <label><small>Subdivision</small></label>
          <b-form-input required v-model="infoCopy.subdivision"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group>
          <label><small>Town / City</small></label>
          <b-form-input required v-model="infoCopy.town"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group>
          <label><small>Province</small></label>
          <b-form-input required v-model="infoCopy.province"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="6">
        <b-form-group>
          <label><small>Business Contact No.</small></label>
          <b-form-input
            required
            v-model="infoCopy.contactNo"
            type="tel"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="6">
        <b-form-group>
          <label><small>Business Email</small></label>
          <b-form-input
            required
            v-model="infoCopy.email"
            type="email"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group>
          <label><small>Kind of Ownership</small></label>
          <b-form-radio-group
            stacked
            name="ownership-kind"
            required
            v-model="infoCopy.ownershipKind"
            :options="ownershipOptions"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group>
          <label><small>Office Category</small></label>
          <b-form-radio-group
            stacked
            name="office-category"
            required
            v-model="infoCopy.officeCategory"
            :options="officeOptions"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4">
        <b-form-group>
          <label><small>Capital Investment</small></label>
          <b-form-input
            type="number"
            required
            v-model.number="infoCopy.capitalInvestment"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group>
          <label><small>DTI/SEC No.</small></label>
          <b-form-input required v-model="infoCopy.dtisec"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col lg="4">
        <b-form-group>
          <label><small>Date Registered</small></label>
          <b-form-input
            required
            v-model="infoCopy.dateRegistered"
            type="date"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end">
      <b-button
        v-if="$store.state.user.role !== 'admin'"
        variant="primary"
        type="submit"
        @submit="handleSubmit"
        >Submit</b-button
      >
    </div>
  </form>
</template>

<script>
  export default {
    name: 'BusinessFormInfo',

    props: {
      info: {
        type: Object,
        required: true
      }
    },

    data () {
      return {
        infoCopy: { ...this.info },

        ownershipOptions: [
          { value: 'Single Proprietorship', text: 'Single Proprietorship' },
          { value: 'Partnership', text: 'Partnership' },
          { value: 'Corporation', text: 'Corporation' },
          { value: 'Cooperative', text: 'Cooperative' },
          {
            value: 'Allied Capital Investment',
            text: 'Allied Capital Investment'
          }
        ],

        officeOptions: [
          { value: 'Principal Office', text: 'Principal Office' },
          { value: 'Branch Office', text: 'Branch Office' },
          { value: 'Warehouse', text: 'Warehouse' },
          { value: 'Plantation', text: 'Plantation' },
          { value: 'Experimental Farm', text: 'Experimental Farm' }
        ]
      }
    },

    watch: {
      info: {
        deep: true,
        handler: 'copyInfo'
      }
    },

    methods: {
      copyInfo (newInfo) {
        this.infoCopy = { ...newInfo }
      },

      handleSubmit () {
        this.$emit('submitting', this.infoCopy)
      }
    }
  }
</script>

<style>
</style>
