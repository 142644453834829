<template>
  <b-container>
    <h4 class="page-title">{{ $route.meta.title }}</h4>
    <b-row>
      <b-col>
        <b-card class="p-4 mb-4">
          <business-form-info
            :info="info"
            @submitting="saveBusiness"
          ></business-form-info>
        </b-card>
      </b-col>
    </b-row>
    <app-spinner ref="spinner"></app-spinner>
    <app-toast ref="toast"></app-toast>
  </b-container>
</template>

<script>
  import BusinessService from '@/services/BusinessService'
  import BusinessFormInfo from '@/components/BusinessFormInfo'

  export default {
    name: 'BusinesesEditor',

    props: ['id'],

    components: {
      BusinessFormInfo
    },

    data () {
      return {
        info: {
          userId: this.$store.state.user.id,
          name: '',
          tradeName: '',
          presidentFirstName: '',
          presidentLastName: '',
          presidentMi: '',
          subdivision: '',
          town: '',
          province: '',
          contactNo: '',
          email: '',
          ownershipKind: '',
          officeCategory: '',
          capitalInvestment: '',
          dtisec: '',
          dateRegistered: ''
        }
      }
    },

    methods: {
      async loadBusiness () {
        if (!this.id) return

        this.$refs.spinner.show()

        try {
          this.info = await BusinessService.findById(this.id)
          this.$refs.spinner.hide()
        } catch (error) {
          this.$refs.spinner.hide()
          this.$refs.toast.error(error.response.data.message)
        }
      },

      async saveBusiness (businessData) {
        // Show spinner
        this.$refs.spinner.show()

        try {
          // Make api call
          this.info = await BusinessService.save(businessData)

          // Hide spinner
          this.$refs.spinner.hide()

          // Show success notif
          this.$refs.toast.success('Business info saved successfully.')
        } catch (error) {
          // Hide spinner
          this.$refs.spinner.hide()

          // Show error notif
          this.$refs.toast.error(error.response.data.message)
        }
      }
    },

    mounted () {
      this.loadBusiness()
    }
  }
</script>

<style>
</style>
